import { css } from "@emotion/react";

import Head from "next/head";
import { useRouter } from "next/router";

import { ErrorCard } from "components/shared/error/ErrorCard";
import { Button } from "components/shared/library/Button";

import { t } from "utils/translation";
import { getHomeUrl } from "utils/urls";

const style = {
  loginButton: css({
    marginBottom: 16,
  }),
};

export default function NotFound() {
  const router = useRouter();
  return (
    <div>
      <Head>
        <title>{t("Oops - Page Not Found")}</title>
        <meta name="ribbon" content={"Onboarding"} />
        <link rel="shortcut icon" href="/images/favicon.ico" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/images/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
      </Head>
      <ErrorCard
        title={t("Oops - Page Not Found")}
        subtitle={t("We couldn't find the page you're looking for")}
      >
        <div css={style.loginButton}>
          <Button
            onClick={() => {
              router.push(getHomeUrl());
            }}
            isFullWidth
          >
            {t("Take me home")}
          </Button>
        </div>
      </ErrorCard>
    </div>
  );
}
